<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="取号设置" />
        <div style="margin-top:20px">
            <el-form ref="form" :model="form" label-width="120px" size="small">
                <el-form-item label="过号重取">
                    <el-switch v-model="form.queue_reacquire_after_expire" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
                <el-form-item label="接种号数量">
                    <el-input v-model="form.jz_num" placeholder="请输入内容" type="number" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="体检号数量">
                    <el-input v-model="form.tj_num" placeholder="请输入内容" type="number" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="上午可用时间">
                <el-time-select placeholder="起始时间" v-model="form.queue_timerange[0]"  style="width: 39%" :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30'
                    }">
                </el-time-select> -
                <el-time-select placeholder="结束时间" v-model="form.queue_timerange[1]"  style="width: 40%" :picker-options="{
                        start: '00:30',
                        step: '00:30',
                        end: '24:00',
                        minTime: form.queue_start_time
                    }">
                </el-time-select>
            </el-form-item>
                <el-form-item label="下午可用时间">
                    <el-time-select placeholder="起始时间" v-model="form.xqueue_timerange[0]" style="width: 39%" :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30'
                    }">
                    </el-time-select> -
                    <el-time-select placeholder="结束时间" v-model="form.xqueue_timerange[1]" style="width: 40%"  :picker-options="{
                        start: '00:30',
                        step: '00:30',
                        end: '24:00',
                        minTime: form.queue_start_time
                    }">
                    </el-time-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'settings-queue',
    path: '/main/settings/queue',
    data() {
        return {
            hospitalList: [],
            form: { queue_reacquire_after_expire:true, queue_timerange: ['09:00', '11:00'] ,xqueue_timerange:['12:00', '17:00']}
        }
    },
    methods: {
        async onSubmit() {
            console.log(this.form)
            const resp = await this.$http.post('/settings/queue', this.form)
            if (resp.data.code == 200) {
                this.$message.success('保存成功')
                this.getData()
            } else {
                this.$message.error('失败:' + resp.data.msg)
            }
        },
        async getData() {
            const resp = await this.$http.get('/settings/queue')
            this.form = resp.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
