var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-page-header',{attrs:{"content":"取号设置"},on:{"back":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"过号重取"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.queue_reacquire_after_expire),callback:function ($$v) {_vm.$set(_vm.form, "queue_reacquire_after_expire", $$v)},expression:"form.queue_reacquire_after_expire"}})],1),_c('el-form-item',{attrs:{"label":"接种号数量"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请输入内容","type":"number"},model:{value:(_vm.form.jz_num),callback:function ($$v) {_vm.$set(_vm.form, "jz_num", $$v)},expression:"form.jz_num"}})],1),_c('el-form-item',{attrs:{"label":"体检号数量"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"placeholder":"请输入内容","type":"number"},model:{value:(_vm.form.tj_num),callback:function ($$v) {_vm.$set(_vm.form, "tj_num", $$v)},expression:"form.tj_num"}})],1),_c('el-form-item',{attrs:{"label":"上午可用时间"}},[_c('el-time-select',{staticStyle:{"width":"39%"},attrs:{"placeholder":"起始时间","picker-options":{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                }},model:{value:(_vm.form.queue_timerange[0]),callback:function ($$v) {_vm.$set(_vm.form.queue_timerange, 0, $$v)},expression:"form.queue_timerange[0]"}}),_vm._v(" - "),_c('el-time-select',{staticStyle:{"width":"40%"},attrs:{"placeholder":"结束时间","picker-options":{
                    start: '00:30',
                    step: '00:30',
                    end: '24:00',
                    minTime: _vm.form.queue_start_time
                }},model:{value:(_vm.form.queue_timerange[1]),callback:function ($$v) {_vm.$set(_vm.form.queue_timerange, 1, $$v)},expression:"form.queue_timerange[1]"}})],1),_c('el-form-item',{attrs:{"label":"下午可用时间"}},[_c('el-time-select',{staticStyle:{"width":"39%"},attrs:{"placeholder":"起始时间","picker-options":{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                }},model:{value:(_vm.form.xqueue_timerange[0]),callback:function ($$v) {_vm.$set(_vm.form.xqueue_timerange, 0, $$v)},expression:"form.xqueue_timerange[0]"}}),_vm._v(" - "),_c('el-time-select',{staticStyle:{"width":"40%"},attrs:{"placeholder":"结束时间","picker-options":{
                    start: '00:30',
                    step: '00:30',
                    end: '24:00',
                    minTime: _vm.form.queue_start_time
                }},model:{value:(_vm.form.xqueue_timerange[1]),callback:function ($$v) {_vm.$set(_vm.form.xqueue_timerange, 1, $$v)},expression:"form.xqueue_timerange[1]"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("保存")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }